import React, { Component } from "react"
import classNames from "classnames"
import {
  isMobileOnly,
  isMobile,
  MobileView,
  BrowserView
} from "react-device-detect"
import PlayPause from "./Sizzle/playPause.js"
import SizzleSource from "./SizzleSource"

import DesktopSizzle from "../../src/videos/sizzle_desktop.mp4"
import MobileSizzle from "../../src/videos/sizzle_mobile.mp4"

class SizzleVideo extends Component {
  constructor(props) {
    super(props)
    this.handlePlayPause = this.handlePlayPause.bind(this)
    this.state = {
      playing: true,
      isPhonePortrait: 0,
      hidden: true,
      isLandscape: this.props.isLandscape,
      isPortrait: this.props.isPortrait,
      isMobileOnly: isMobileOnly,
      vidSource: ""
    }
    this.vidRef = React.createRef()
    this.playerWrapper = {}
    this.handlePlayPause = this.handlePlayPause.bind(this)
  }
  componentDidMount() {
    const video = document.querySelector("video")

    video.addEventListener("mousedown", e => {
      requestAnimationFrame(() => {})
    })
    let underTablet = window.innerWidth < 576
    if (underTablet == true) {
      this.setState({
        hidden: false,
        isMobileOnly: isMobileOnly,
        isLandscape: this.props.isLandscape
      })
    } else {
      this.playerWrapper = {
        position: "relative"
      }
      this.setState({
        hidden: false,
        isMobileOnly: isMobileOnly,
        isLandscape: this.props.isLandscape
      })
    }

    if (isMobile) {
      this.setState({
        vidSource: MobileSizzle
      })
    } else {
      this.setState({
        vidSource: DesktopSizzle
      })
    }
    setTimeout(() => {
      this.vidRef.current.load()
    }, 50)
  }

  componentDidUpdate(prevState) {
    if (isMobileOnly) {
      if (this.props.isLandscape !== this.state.isLandscape) {
        if (this.props.isLandscape) {
          this.setState({
            isLandscape: this.props.isLandscape,
            isPortrait: this.props.isPortrait
          })
        } else {
          this.setState({
            isLandscape: this.props.isLandscape,
            isPortrait: this.props.isPortrait
          })
        }
      }
    }
  }

  handlePlayPause() {
    if (this.state.playing) {
      this.vidRef.current.pause()
      this.setState({ playing: false })
    } else {
      this.vidRef.current.play()
      this.setState({ playing: true })
    }
  }

  render() {
    let homePlayerClasses = classNames({
      hidden: this.state.hidden,
      "test-1 home-sizzle": true
    })

    return (
      <div className={homePlayerClasses} style={{ position: "relative" }}>
        <video
          ref={this.vidRef}
          className="sizzle"
          width="100%"
          height=""
          playsInline
          loop
          muted
          autoPlay
          controls={false}>
          {/* <SizzleSource
            onChange={() => {
              this.vidRef.current.load()
            }}
          /> */}

          <source src={this.state.vidSource} type="video/mp4" />
        </video>

        <PlayPause
          language={this.props.language ? this.props.language : null}
          handlePlayPause={this.handlePlayPause}
          videoPlaying={this.state.playing}
        />
      </div>
    )
  }
}

export default SizzleVideo

import React from "react"
import "../../components/animations/scroll.scss"
import PropTypes from "prop-types"
// import TheaterVideo from "@components/Theater/TheaterVideo"
import { Text } from "../../components/Core"

const StaticHeading = ({ heading, subheading, youtube, language }) => {
  return (
    <div
      style={{
        textAlign: "left"
      }}
      className="static-heading">
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset", textAlign: "left" }}>
        <Text
          role="heading"
          className="curtain-heading h3-style"
          as="h1"
          text={heading}
        />
        <Text
          useStringReplace
          className="above-text__subheader"
          as="span"
          text={subheading}
        />
      </div>

      {/* {youtube && youtube !== "?" && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
          controls
          playing
          onlyButton
          sizzleButton
          buttonClass="sizzle-button-under"
          compId="sizzleTheaterButton"
          language={language}
        />
      )} */}
    </div>
  )
}

StaticHeading.propTypes = {
  language: PropTypes.string
}

export default StaticHeading

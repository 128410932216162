import React from "react"

import { Section, Columns } from "../../components/Containers"
import { Text, Image } from "../../components/Core"
import { ButtonGroupMap } from "../../components/Button"

function FirstTime(props) {
  return (
    <Section colorBack={props.colorBack} className={`first-time`}>
      <Columns sideColumnsSize={4}>
        <div className="column" data-aos="fade-up" data-aos-duration="1200">
          <Text
            as="h3"
            className="has-text-centered mt-0"
            style={{ marginBottom: "16px" }}
            text={props.post.firstTimePatient.heading}
          />
          <Text
            as="p"
            className="has-text-centered-tablet"
            text={props.post.firstTimePatient.text}
          />
          <ButtonGroupMap
            buttons={props.post.firstTimePatient.buttons}
            isCentered
            noReverse={props.noReverse}
          />
        </div>
      </Columns>
      {/* <div className="columns" data-aos="fade-up" data-aos-duration="1200">
        <div className="column is-4"></div>
        <div className="column">
          <div className="columns has-text-centered-tablet">
            <div className="column">
              <Text
                as="h3"
                style={{ marginBottom: "16px", marginTop: 0 }}
                text={props.post.firstTimePatient.heading}
              />
            </div>
          </div>
          <div className="columns">
            <Text
              className="column large-p has-text-centered-tablet"
              text={props.post.firstTimePatient.text}
            />
          </div>
          <div className="columns">
            <div className="column">
              <ButtonGroupMap
                buttons={props.post.firstTimePatient.buttons}
                isCentered
                noReverse={props.noReverse}
              />
            </div>
          </div>
        </div>
        <div className="column is-4"></div>
      </div> */}
    </Section>
  )
}

export default FirstTime
